import { ChevronDown } from 'lucide-react';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { clearSessionStorage } from 'untils/auth';

const sessionClear = [
  'from',
  'to',
  'flyLocationTo',
  'flyLocationForm',
  'userFormData',
  'emptyLegsStartDate',
  'emptyLegsEndDate',
];

const Navbar = ({ isScroll, history, name }) => {
  const initPage = (e, url) => {
    e.preventDefault();
    sessionClear.forEach((element) => {
      clearSessionStorage(element);
    });
    history.push(`/${url}`);
  };

  return (
    <ul className={`header-nav ${name}`}>
      <li className="header-nav-parent">
        <span>
          <a
            href="javascripts:;"
            onClick={(e) => {
              initPage(e, '');
            }}
          >
            Search Flights
          </a>
          <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
        </span>
        <ul className="header-nav-children about-airacer">
          <li>
            <NavLink
              to="/charterQuote"
              onClick={(e) => {
                initPage(e, 'charterQuote');
              }}
            >
              Private Charters
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="header-nav-parent">
        <span>
          <NavLink
            to="/emptyLeg"
            onClick={(e) => {
              initPage(e, 'emptyLeg');
            }}
          >
            Empty Leg Flights
          </NavLink>
        </span>
      </li>
      <li className="header-nav-parent">
        <span>
          <Link to="javascripts:;">Jet Guide</Link>
          <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
        </span>
        <ul className="header-nav-children about-airacer">
          <li>
            <NavLink to="/private-jets">Private Jet Types</NavLink>
          </li>
        </ul>
      </li>
      <li className="header-nav-parent">
        <NavLink to="/membership">Memberships</NavLink>
      </li>
      <li className="header-nav-parent">
        <NavLink to="/jet-card-membership">Jet Card</NavLink>
      </li>{' '}
      <li className="header-nav-parent">
        <NavLink to="/blog">Blog</NavLink>
      </li>
      <li className="header-nav-parent">
        <span>
          <Link to="javascripts:;">Company</Link>
          <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
        </span>
        <ul className="header-nav-children about-airacer">
          <li>
            <NavLink to="/contactUs">Contact Us</NavLink>
          </li>
          <li>
            <NavLink to="/aboutUs">About Us</NavLink>
          </li>
          <li>
            <NavLink to="/partnerWithUs">Partner With Us</NavLink>
          </li>
          <li>
            <NavLink to="/affiliatePartner">Become an Affiliate</NavLink>
          </li>

          <li>
            <NavLink to="/jetSale">Jet Acquisitions</NavLink>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default Navbar;
